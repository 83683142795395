<template>
  <moe-page title="用户设备管理">
    <!-- 筛选信息 -->
    <moe-inquire @search="equipmentSearch">
      <el-form-item label="设备类型">
        <el-select v-model="equipmentParams.type" placeholder="选择设备类型" clearable>
          <el-option v-for="(item, index) in deviceModelList" :key="index" :label="`${item.name}(${item.productModel})`" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="所属用户">
        <el-input
          v-model.trim="equipmentParams.name"
          placeholder="输入所属用户"
          maxlength="100"
          clearable
        />
      </el-form-item>
      <el-form-item label="设备ID">
        <el-input
          v-model.trim="equipmentParams.id"
          placeholder="输入设备ID"
          maxlength="100"
          clearable
        />
      </el-form-item>
      <el-form-item label="设备UID">
        <el-input
          v-model.trim="equipmentParams.uid"
          placeholder="输入设备UID"
          maxlength="100"
          clearable
        />
      </el-form-item>
      <el-form-item label="设备序列号">
        <el-input
          v-model.trim="equipmentParams.serial"
          placeholder="输入设备序列号"
          maxlength="100"
          clearable
        />
      </el-form-item>
    </moe-inquire>

    <!-- 设备信息 -->
    <moe-table ref="equipmentTable" url="/device/pageList" :params="equipmentParams">
      <el-table-column prop="type" label="设备类型" min-width="100" :show-overflow-tooltip="true" :formatter="formatterType" />
      <el-table-column prop="id" label="设备ID" min-width="150" :show-overflow-tooltip="true" />
      <el-table-column prop="uid" label="设备UID" min-width="150" :show-overflow-tooltip="true" />
      <el-table-column prop="serial" label="设备序列号" min-width="150" :show-overflow-tooltip="true" />
      <el-table-column prop="model" label="设备型号" min-width="100" :show-overflow-tooltip="true" :formatter="formatterModel" />
      <el-table-column prop="edition" label="固件版本" min-width="100" />
      <el-table-column prop="img" label="设备图片" min-width="100">
        <template slot-scope="{ row }">
          <moe-image :src="row.img" width="100px" height="50px" />
        </template>
      </el-table-column>
      <el-table-column prop="userName" label="所属用户" min-width="100" />
      <el-table-column prop="phone" label="手机号" min-width="150" />
      <el-table-column label="操作" width="150" fixed="right">
        <div class="moe-table__btns" slot-scope="{ row }">
          <el-button
            type="success"
            size="mini"
            icon="el-icon-view"
            @click="$router.push(`/user-equipment/detail?id=${row.id}`)">
            详情
            </el-button>
        </div>
      </el-table-column>
    </moe-table>
  </moe-page>
</template>

<script>
export default {
  name: 'UserEquipmentList',
  data() {
    //设备筛选信息
    let equipmentParams = {
      pageNum: 1,   //当前页
      pageSize: 10, //每页条数
      type: '',     //设备类型
      name: '',     //设备名称
      id: '',       //设备ID
      uid: '',      //设备uid
      serial: '',   //设备序列号
    };

    return {
      equipmentParams, //设备筛选信息
      deviceModelList: [],
    };
  },
  methods: {
    /**
     * 设备搜索
     **/
    equipmentSearch(isSearch) {
      if (!isSearch) {
        this.equipmentParams = {
          pageNum: 1, //当前页
          pageSize: 10, //每页条数
          type: '', //设备类型
          name: '', //设备名称
          UID: '', //设备ID
        };
      }

      //刷新设备表格
      this.$refs.equipmentTable.searchData();
    },
    formatterType(row) {
      let findItem = this.deviceModelList.find(({ id }) => id === row.type)
      if (findItem) {
        return findItem.name
      }
    },
    formatterModel(row) {
      let findItem = this.deviceModelList.find(({ id }) => id === row.type)
      if (findItem) {
        return findItem.productModel
      }
    },
  },
  mounted() {
    /** 查看所有设备型号 */
    this.$moe_api.EQUIPMENT_API.readAll().then((data) => {
      if (data.code === 200) {
        this.deviceModelList = data.result;
      }
    })
  }
};
</script>

<style>
</style>
